import styled from "styled-components";
import { TABLET_MAX_WIDTH } from "@constants";
import { MOBILE_MIN_WIDTH } from "../../../../constants";

export const OfferSection = styled.div`
    margin-top: 5rem;
`;

export const SquareSection = styled.div`
    width: 100%;
    margin: 5rem 0;
`;

export const SquareOfferSection = styled.div`
    border: 0.01rem solid #4a2b43;
    padding: 5rem 9.375rem;
    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        padding: 6.5rem 6.25rem;
    }
`;

export const SquareSectionContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 3rem -16rem 0;
    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        display: block;
        margin: 3rem -7rem 0;
    }
`;

export const SquareSectionButtonWrapper = styled.div`
    margin-top: 7.5rem;
    padding: 0 0 2.5rem 0;
    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        background: rgba(151, 71, 255, 0.05);
        padding: 2.5rem;
        margin-top: 5rem;
    }
    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        background: transparent;
        margin: 2.5rem 0 -2.5rem;
        padding: 0
    }
`;
export const ButtonWrapper = styled.a`
    max-width: 15.3125rem;
    text-decoration: none;
    display: block;
    margin: 0 auto;
    button {
        height: 3.5rem;
        justify-content: center;
    }
    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        margin: auto;
        display: inline;
        button {
            height: 3.5rem;
            max-width: 100%;
            justify-content: flex-start;
        }
    }
`;
export const ButtonDescription = styled.h5`
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 700;
    text-align: center;
    width: 100%;
    margin: 0 0 1rem 0;
    display: block;
    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        font-size: 1.5rem;
        line-height: 2rem;
    }
    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        font-size: 1rem;
        line-height: 1.5rem;
        text-align: start;
    }
`;
export const MainOffersPresentationHeader = styled.div`
    text-align: center;
    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        text-align: start;
    }
`;
