import styled, { css } from "styled-components";
import { TABLET_MAX_WIDTH, MOBILE_MIN_WIDTH } from "@constants";

export const Description = styled.p`
    line-height: 1.5rem;
    margin: 0;
    color: var(--main-font-color);

    ${(props) => props.isHeadingCentered && css`
        text-align: center;
        @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
            text-align: start;
        }
    `}
`;

export const Title = styled.h2`
    font-size: 2.5rem;
    line-height: 3rem;
    margin: 0 0 1rem;
    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        font-size: 2rem;
        line-height: 2.5rem;
    }
    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        font-size: 1.5rem;
        line-height: 2rem;
    }
`;

export const ContainerWithoutMargins = styled.div`
    margin: 0 -16rem;

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        margin: 0 -7rem;
    }

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        margin: 0 -3rem;
    }
`;

export const MainOffersPresentationSectionWrapper = styled.div`
    margin: 5rem 0;
    max-width: 90%;

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        max-width: 100%;
    }
`;

export const MainOffersPresentationTitle = styled.h2`
    font-size: 2.5rem;
    line-height: 3rem;
    margin: 0 0 1.5rem;
    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        font-size: 2rem;
        line-height: 2.5rem;
    }
    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        font-size: 1.5rem;
        line-height: 2rem;
    }

    ${(props) => props.isHeadingCentered && css`
        text-align: center;
        @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
            text-align: start;
        }
    `}
`;

export const OfferSectionTitle = styled.h3`
    font-size: 2rem;
    line-height: 2.5rem;
    margin: 0 0 2rem;

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        font-size: 1.5rem;
        line-height: 2rem;
    }
`;
