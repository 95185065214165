import React from "react";
import {
    OfferSection,
    SquareOfferSection,
    SquareSection,
    SquareSectionContainer,
    MainOffersPresentationHeader,
    ButtonDescription,
    SquareSectionButtonWrapper,
    ButtonWrapper,
} from "./MainOffersPresentationSection.style";
import {
    Description,
    MainOffersPresentationSectionWrapper,
    MainOffersPresentationTitle,
    OfferSectionTitle,
} from "../CommonStyles/CommonStyles.style";
import Button from "../../../Common/Button/Button";

const MainOffersPresentationSection = ({
    offers,
    title,
    description,
    isHeadingCentered,
    isSquareSection,
    buttonText,
    buttonNavigation,
    textUnderSection,
}) => {
    if (isSquareSection === "true") {
        return (
            offers && (
                <SquareSection>
                    <MainOffersPresentationHeader>
                        <MainOffersPresentationTitle>
                            {title}
                        </MainOffersPresentationTitle>
                        <Description>{description}</Description>
                    </MainOffersPresentationHeader>
                    <SquareSectionContainer>
                        {offers.map(offer => (
                            <SquareOfferSection>
                                <OfferSectionTitle>
                                    {offer.title}
                                </OfferSectionTitle>
                                <Description>{offer.text}</Description>
                            </SquareOfferSection>
                        ))}
                    </SquareSectionContainer>
                    {buttonText && (
                        <SquareSectionButtonWrapper>
                            <ButtonDescription>
                                {textUnderSection}
                            </ButtonDescription>
                            <ButtonWrapper href={buttonNavigation}>
                                <Button text={buttonText} />
                            </ButtonWrapper>
                        </SquareSectionButtonWrapper>
                    )}
                </SquareSection>
            )
        );
    }
    return (
        offers && (
            <MainOffersPresentationSectionWrapper>
                <MainOffersPresentationTitle isHeadingCentered={isHeadingCentered}>
                    {title}
                </MainOffersPresentationTitle>
                <Description isHeadingCentered={isHeadingCentered}>{description}</Description>
                {offers.map(offer => (
                    <OfferSection>
                        <OfferSectionTitle>{offer.title}</OfferSectionTitle>
                        <Description>{offer.text}</Description>
                    </OfferSection>
                ))}
            </MainOffersPresentationSectionWrapper>
        )
    );
};

export default MainOffersPresentationSection;
